import styled from "styled-components";
import { Box } from "@mui/material";

export const TestimonialContainer = styled(Box)`
  width: 100%;
  padding-top: 2rem;
  max-width: 120rem;
  height: 100%;
  padding: 1.5rem;
  background: rgba(0, 47, 166, 0.05);
  @media only screen and (max-width: 773px) {
    padding: 1.5rem 1rem;
  }
  @media only screen and (max-width: 587px) {
    height: max-content;
  }
  @media only screen and (max-width: 360px) {
    height: max-content;
  }
`;

export const TestimonialChild = styled(Box)`
  width: 80%;
  height: 100%;
  margin: 1rem auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  @media only screen and (max-width: 899px) {
    width: 90%;
  }
  @media only screen and (max-width: 773px) {
    width: 94%;
  }
`;

export const TestimonialBoxA = styled(Box)`
  width: 44%;
  margin-top: 1rem;
  padding-right: 2rem;
  @media only screen and (max-width: 615px) {
    width: 42%;
  }
  @media only screen and (max-width: 587px) {
    width: 90%;

    .MuiTypography-root.MuiTypography-h5 {
      text-align: center !important;
    }
  }
`;
export const BoxAChild = styled(Box)`
  display: flex;
  align-items: center;
  .line {
    width: 44px;
    height: 2px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: justify;
  }
  @media only screen and (max-width: 773px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 587px) {
    justify-content: center;
  }
`;

export const TestimonialBoxB = styled(Box)`
  width: 52%;
  height: 70%;
  position: relative;

  .swiper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: auto;
    height: 60vh;
    margin-bottom: 2rem;
    .swiper-button-next {
      background-color: #000d2f;
      border-radius: 50%;
      border: 1px solid #00d285;
      width: 35px;
      height: 35px;
      top: 60%;
      right: 0;
      &::after {
        transform: rotate(90deg);
        font-size: 15px;
        color: #00d285;
      }
    }
    .swiper-button-prev {
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #00d285;
      width: 35px;
      height: 35px;
      top: 40%;
      right: 0;
      left: unset;

      &::after {
        transform: rotate(90deg);
        font-size: 15px;
        color: #00d285;
      }
    }
    .test {
      width: 65%;
      height: 58%;
      position: absolute;
      /* bottom: -1%; */
      right: 14%;
      z-index: 2;
    }
    .swiper-wrapper {
      width: 80%;
      height: 68%;
      position: relative;
      z-index: 4;
      margin-bottom: 1rem;
      .swiper-slide {
        width: 90%;
        height: 78%;
        margin-bottom: 4rem;
        /* overflow-x: hidden; */
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 4;
        .testimonials {
          width: 85%;
          height: auto !important;
          margin-bottom: 4rem;
          margin-top: 2rem;
        }
      }
      @media only screen and (max-width: 1000px) {
        .swiper-slide {
          .testimonials {
            width: 90%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 773px) {
    width: 54%;
    .swiper {
      height: 45vh;
      .swiper-wrapper {
        width: 87%;
        height: 62%;
        .swiper-slide {
        }
      }
    }
  }
  @media only screen and (max-width: 615px) {
    width: 58%;
  }
  @media only screen and (max-width: 587px) {
    width: 90%;
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          .testimonials {
            height: max-content !important;
            padding : 1rem 0.5rem
          }
        }
      }
    }
  }
  @media only screen and (max-width: 440px) {
    .swiper {
      margin-bottom: 0;
      .test {
        display: none;
      }
      .swiper-wrapper {
        .swiper-slide {
          .testimonials {
            width: 99%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    width: 97%;
  }
  @media only screen and (max-width: 360px) {
    width: 97%;
    margin: 0 auto;
    .swiper {
      /* .test {
        display: none;
      } */
      .swiper-wrapper {
        width: 100%;
        .swiper-slide {
          width: 100%;
          .testimonials {
            padding: 0.7rem;
            height: 62% !important;
          }
        }
      }
      .swiper-button-prev {
        top: 90%;
        right: 55%;
        /* &::after {
          transform: rotate(0deg);
        } */
      }
      .swiper-button-next {
        top: 90%;
        right: 38%;
        /* &::after {
          transform: rotate(0deg);
        } */
      }
    }
  }
`;

export const Testimonials = styled(Box)`
  background-color: white;
  padding: 1rem 1.5rem;
  height: max-content;
   width: max-content;
  border-radius: 10px;
  z-index: 2;
  .imgWrapper{
    display: flex;
    justify-content: start;
    justify-items: flex-start;
  }
  .testimony {
    width: max-content;
    max-width: 120px;
    height: 60px;
    object-fit: contain;
  }


  .MuiTypogrpahy-root {
    font-family: "Poppins", sans-serif;
    z-index: 2;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Monteserrat", sans-serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 29px;
    text-align: justify;
    color: #000d2f;
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
    letter-spacing: -0.5px;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 21px;
    text-align: start;
    color: #000d2f;
    margin-bottom: 0.5rem;
  }
`;
export const SubText = styled(Box)`
  width: 100%;
  position: relative;
  margin-top: 1rem;
  z-index: 2;
  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 26px !important;
    text-align: start;
    /* color: #000d2f; */
    margin-bottom: 0;
  }

  .company {
      color: #333333 !important;
    }
    
    .name {
        color: #AAAAAA !important;
  }
`;
