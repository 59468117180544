import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalStyles } from "./app.style";
import { lazy } from "react";
import Home from "./pages/home/Home";
import Quote from "./pages/quote/Quote";
import Call from "./pages/call/Call";
import Blog from "./pages/blog/Blog";
import SingleBlog from "./pages/single-blog/SingleBlog";
import { Suspense } from "react";
import LoadingPage from "./components/shared-components/Loading/Loading";

const LazyContact = lazy(() => import("./pages/contact/Contact"));
const LazyAbout = lazy(() => import("./pages/about/About"));
const LazyExpertise = lazy(() => import("./pages/expertise/Expertise"));
const LazyPortfolio = lazy(() => import("./pages/portfolio/Portfolio"));
const LazyProduct = lazy(() => import("./pages/product/Product"));
const LazyPaternship = lazy(() => import("./pages/partnership/Partnership"));
function App() {
  return (
    <div className="App">
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route
            path="/about"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <LazyAbout />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <LazyContact />
              </Suspense>
            }
          />
          <Route path="/quote" element={<Quote />} />
          <Route path="/call" element={<Call />} />
          <Route
            path="/expertise"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <LazyExpertise />
              </Suspense>
            }
          />
          <Route path="/partnership" element={
            <Suspense fallback={<LoadingPage/>}>


              <LazyPaternship />
            </Suspense>


          } />
          <Route
            path="/product"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <LazyProduct />
              </Suspense>
            }
          />
          <Route
            path="/portfolio"
            element={
              <Suspense fallback={<LoadingPage/>}>
                <LazyPortfolio />
              </Suspense>
            }
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/post" element={<SingleBlog />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
