import React from "react";
import {
  AboutBoxA,
  AboutBoxB,
  AboutChild,
  AboutContainer,
  AboutTitle,
  ButtonBox,
} from "./about.style";
import partnership from "../../../assets/images/deftpress.PNG";
import { Button, Typography } from "@mui/material";

const About = () => {
  return (
    <AboutContainer style={{ backgroundColor: "#1e1e1e" }}>
      <AboutTitle style={{ display: "block" }}>
        <div className="line"></div>
        <Typography variant="h6" className="header">
          Imagine you can build a website by just filling a form.
        </Typography>
        <p className="subtext">How many websites would you have?</p>
      </AboutTitle>
      <AboutChild>
        <AboutBoxA>
          <img src={partnership} alt="partnership" />
        </AboutBoxA>
        <AboutBoxB>
          <Typography variant="h4">Deftpress</Typography>
          <Typography variant="body1">
            Deftpress is a CMS platform that allows you to create a website in
            minutes, without any technical skills. You can choose from a variety
            of templates that suit your needs and preferences, and customize
            them with your own content. Whether you are a blogger, a business
            owner, a student, or a professional, Deftpress has a template for
            you. With Deftpress, you can own a website seamlessly and quickly,
            and share it with the world. Deftpress is specially created to cater
            to anyone who wants to deliver website fast regardless of your
            technical skills.
          </Typography>
          <Typography variant="body1">
            You can also manage your website easily with Deftpress's
            user-friendly dashboard. Deftpress is the best CMS platform for
            anyone who wants to have an online presence.
          </Typography>
          <ButtonBox>
              <a
                href="http://deftpress.com"
                target="blank"
                rel="Deftpress"
              >
            <Button variant="contained">
                <Typography variant=""> Try for Free</Typography>
            </Button>
              </a>
          </ButtonBox>
        </AboutBoxB>
      </AboutChild>
    </AboutContainer>
  );
};

export default About;
