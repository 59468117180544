import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import {
  ButtonBox,
  MenuBar,
  MenuBox,
  MenuLogoBox,
  MenuToolBar,
} from "./navbar.style";
import logo from "../../../assets/images/logo.png";

const Navbar = ({ isBlack }) => {
  const [state, setState] = useState({
    right: false,
  });
  const [backgroundColor, setBackgroundColor] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const changeNavbarColor = () => {
    if (window.scrollY >= 50) {
      setBackgroundColor(true);
    } else {
      setBackgroundColor(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const [active, setActive] = useState("");
  useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      setActive("home");
    } else if (path === "/about") {
      setActive("about");
    } else if (path === "/product") {
      setActive("product");
    } else if (path === "/contact") {
      setActive("contact");
    } else if (path === "/blog") {
      setActive("blog");
    } else if (path === "/blog/post") {
      setActive("blog");
    } else if (path === "/portfolio") {
      setActive("portfolio");
    } else if (path === "/expertise") {
      setActive("expertise");
    }
  }, [active]);
  const list = (anchor) => (
    <Box role="presentation" onClick={toggleDrawer(anchor, false)}>
      <List>
        <ListItem>
          <Link to="/about">
            {" "}
            <ListItemText primary="ABOUT" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/portfolio">
            {" "}
            <ListItemText primary="PORTFOLIO" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/product">
            <ListItemText primary="PRODUCT" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/expertise">
            <ListItemText primary="EXPERTISE" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/contact">
            {" "}
            <ListItemText primary="CONTACT" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/blog">
            <ListItemText primary="BLOG" />
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <MenuBox
      sx={{ flexGrow: 1 }}
      isBlack={isBlack}
      className={backgroundColor ? "colorChange" : ""}
    >
      <MenuBar>
        <MenuToolBar>
          <MenuLogoBox>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </MenuLogoBox>
          <ButtonBox sx={{ display: { xs: "none", md: "flex" } }}>
            <Link to="/about">
              {" "}
              <Button
                variant="text"
                className={active === "about" ? "active" : null}
              >
                ABOUT
              </Button>
            </Link>
            <Link to="/portfolio">
              {" "}
              <Button
                variant="text"
                className={active === "portfolio" ? "active" : null}
              >
                PORTFOLIO
              </Button>
            </Link>
            <Link to="/product">
              {" "}
              <Button
                variant="text"
                className={active === "product" ? "active" : null}
              >
                PRODUCTS
              </Button>
            </Link>
            <Link to="/expertise">
              {" "}
              <Button
                variant="text"
                className={active === "expertise" ? "active" : null}
              >
                EXPERTISE
              </Button>
            </Link>
            <Link to="/contact">
              {" "}
              <Button
                variant="text"
                className={active === "contact" ? "active" : null}
              >
                CONTACT
              </Button>
            </Link>
            <a target="blank" href="https://blog.petrongsoftware.com/">
              <Button
                variant="text"
                className={active === "blog" ? "active" : null}
              >
                BLOG
              </Button>
            </a>
          </ButtonBox>
          <Box
            sx={{ display: { xs: "flex", md: "none" } }}
            className="menu-div"
          >
            <>
              {["right"].map((anchor) => (
                <div key={anchor}>
                  <Button onClick={toggleDrawer(anchor, true)}>
                    <MenuIcon />
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </div>
              ))}
            </>
          </Box>
        </MenuToolBar>
      </MenuBar>
    </MenuBox>
  );
};

export default Navbar;
