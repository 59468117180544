import React from "react";
import {
  BackBox,
  BoxChild,
  ContactChild,
  CopyrightBox,
  FooterBox,
  FooterBoxChild,
  FooterChild,
  FooterContainer,
  SocialBox,
} from "./footer.style";
import overlay from "../../../assets/images/footer-overlay.png";
import { Typography } from "@mui/material";
import linkedin from "../../../assets/icons/linkedin.png";
import facebook from "../../../assets/icons/facebook.png";
import instagram from "../../../assets/icons/instagram.png";
// import youtube from "../../assets/icons/youtube.png";
import phone from "../../../assets/icons/phone.png";
import email from "../../../assets/icons/email.png";
import logo from "../../../assets/images/logo.png";
// import top from "../../../assets/images/back-to-top.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <FooterContainer>
      <img src={overlay} alt="overlay" className="overlay" />
      <FooterChild>
        <FooterBox className="first">
          <Typography variant="h6">QUICK LINKS</Typography>
          <FooterBoxChild>
            <BoxChild>
              <Typography variant="body1">
                <Link to="/">Home</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/about">About</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/portfolio">Projects</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/contact">Contact</Link>
              </Typography>
              <Typography variant="body1">
                <a target="blank" href="https://blog.petrongsoftware.com/">
                  Blog
                </a>
              </Typography>
              {/* <Typography variant="body1">
                <Link to="/">Our Policy</Link>
              </Typography> */}
              <Typography variant="body1">
                <Link to="/contact">Career</Link>
              </Typography>
            </BoxChild>
          </FooterBoxChild>
        </FooterBox>
        <FooterBox className="second">
          <Typography variant="h6">OUR SERVICES</Typography>
          <Typography variant="body1">Web Development</Typography>
          <Typography variant="body1">Digital Product Design</Typography>
          <Typography variant="body1">Cloud/DevOps</Typography>
          <Typography variant="body1">Mobile Applications</Typography>
          <Typography variant="body1">Brand Design</Typography>
          <Typography variant="body1">Artificial Intelligence</Typography>
          <Typography variant="body1">Blockchain Development</Typography>
        </FooterBox>
        <FooterBox className="third">
          <Typography variant="h6">OUR TECHNOLOGIES</Typography>
          <Typography variant="body1">Javascript & Node JS</Typography>
          <Typography variant="body1">PHP & Laravel</Typography>
          <Typography variant="body1">React & React Native</Typography>
          <Typography variant="body1">Python</Typography>

          <Typography variant="body1">WordPress</Typography>
          <Typography variant="body1">Digital Ocean </Typography>
          <Typography variant="body1">AWS</Typography>
          <Typography variant="body1">
            <Link to="/"></Link>
          </Typography>
        </FooterBox>
        <FooterBox className="fourth">
          <Typography variant="h6">CONTACT & FOLLOW US</Typography>
          <SocialBox>
            <a
              href="https://www.linkedin.com/company/petrong-software-solutions"
              target="blank"
            >
              <img src={linkedin} alt="linkedin logo" className="social-icon" />
            </a>
            <a href="https://www.instagram.com/petrongsoftware/" target="blank">
              <img
                src={instagram}
                alt="instagram logo"
                className="social-icon"
              />
            </a>
            <a target="blank" href="https://www.facebook.com/petrongsoftware">
              <img src={facebook} alt="facebook logo" className="social-icon" />
            </a>
            <a
              target="blank"
              href="https://www.youtube.com/@petrongsoftwaresolutions5419"
            >
              <YouTubeIcon className="social-icon" />
            </a>
          </SocialBox>

          <br />

          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <ContactChild>
              <img src={phone} alt="phone icon" className="contact-icon" />
              <Typography variant="body1">
                <a href="tel:+2348066071971">(234) 8066071971</a>
              </Typography>
            </ContactChild>
            <ContactChild>
              <img src={email} alt="email icon" className="contact-icon" />
              <Typography variant="body1">
                <a href="mailto:contact@petrongsoftware.com">
                  contact@petrongsoftware.com
                </a>
              </Typography>
            </ContactChild>
          </div>
          <Link to="/">
            <img src={logo} alt="company logo" className="logo" />
          </Link>
        </FooterBox>
      </FooterChild>
      {/* <BackBox onClick={backToTop}>
        <img src={top} alt="top icon" className="back-icon" />
      </BackBox> */}
      <CopyrightBox>
        <Typography variant="body1">
          Petrong Software Solution Copyright {year} - All Right Reserved
        </Typography>
      </CopyrightBox>
    </FooterContainer>
  );
};

export default Footer;
