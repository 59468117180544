import {useEffect} from 'react'
import { Helmet } from "react-helmet";
import { CallContainer } from './call.style'
import Navbar from '../../components/shared-components/navbar/Navbar'
import Header from "../../components/call-components/header/Header"
// import Body from "../../components/call-components/body/Body"
import Footer from "../../components/shared-components/footer/Footer";
import { InlineWidget } from "react-calendly";
const Call = () => {
  useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

  return (
    <CallContainer>
       <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Petrong Software Company - Call" />
        <meta name="keywords" content="Petrong, Petrong Software, Petrong Software Solutions" />
        <meta name="author" content="Petrong Software Company" />
         <meta name="robots" content="index,follow" />
        <title>Petrong - Call</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
        </Helmet>
        <Navbar className="contact-navbar" isBlack= "true" />
        <Header />
        {/* <Body /> */}
          <InlineWidget 
            url="https://calendly.com/petrongsoftwaresolutions/bookacall/hide_gdpr_banner=1"
            />
        <Footer />
    </CallContainer>
  )
}

export default Call