import styled from "styled-components";
import { Box } from "@mui/material";
import bg from "../../../assets/images/partnership-bg.png";
export const PartnershipContainer = styled(Box)`
  width: 100%;
  margin-top: 5rem;
  max-width: 120rem;
  height:  max-content;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  padding: 2rem;
  position: relative;

  .partner {
    position: absolute;
    height: 100%;
    top: -5%;
    right: 14%;
    z-index: 0;
  }
  @media only screen and (max-width: 587px) {
    height: 97vh;
    background-color: black;
    background-image: none;
    .partner {
      display: none;
    }
  }
  @media only screen and (max-width: 480px) {
    height: unset;
  }
`;

export const PartnershipChild = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 587px) {
    justify-content: center;
  }
`;
export const PartnershipBoxA = styled(Box)`
  width: 55%;
  height: 100%;
  img {
    width: 90%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin-right: -0.5rem;
  }
  @media only screen and (max-width: 768px) { 
    img {
      height: 90%;
    }
  }
  @media only screen and (max-width: 587px) {
    height: 55%;
    width: 80%;
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
    margin-bottom: 2rem;
    img {
      height: auto;
      /* object-fit: contain; */
    }
  }
  @media only screen and (max-width: 320px) {
    width: 97%;
  }
`;
export const PartnershipBoxB = styled(Box)`
  width: 45%;
  text-align: start;

  .MuiTypography-root.MuiTypography-h4 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 44px;
    color: #262626;
    margin-bottom: 2rem;
    font-family: "Poppins", sans-serif;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 27px;
    text-align: justify;
    color: #262626;
    font-family: "Montserrat", sans-serif;
  }

  a {
    color: white-space;
    text-decoration: none;
    .MuiButton-root.MuiButton-contained {
      font-family: "Montserrat", sans-serif;
      text-transform: none;
      border-radius: 100px;
      background: #00d285;
      margin-top: 2rem;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 0.5rem 3rem;
    }
  }
  @media only screen and (max-width: 976px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 885px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.65rem;
    }
  }
  @media only screen and (max-width: 818px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.55rem;
    }
  }
  @media only screen and (max-width: 773px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.45rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.65rem;
    }
  }
  @media only screen and (max-width: 726px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.35rem;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (max-width: 681px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.25rem;
    }
    .MuiButton-root.MuiButton-contained {
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 634px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.15rem;
    }
  }
  @media only screen and (max-width: 589px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.05rem;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 80%;
    .MuiTypography-root.MuiTypography-h4 {
      color: white;
      margin-bottom: 1rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      color: white;
    }
    .MuiButton-root.MuiButton-contained {
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
  }
  @media only screen and (max-width: 320px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.95rem;
    }
  }
`;
