import React from 'react'
import { Helmet } from "react-helmet";
import { BlogContainer } from './blog.style'
import Navbar from '../../components/shared-components/navbar/Navbar'
import Subfooter from "../../components/shared-components/subfooter/Subfooter";
import Footer from '../../components/shared-components/footer/Footer';
import Header from '../../components/blog-components/header/Header';
import Sub from '../../components/blog-components/sub/Sub';
import Body from '../../components/blog-components/body/Body';
import Main from '../../components/blog-components/main/Main';

const Blog = () => {
  return (
    <BlogContainer>
       <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Petrong Software Company - Blog" />
        <title>Petrong - Blog</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
        </Helmet>
        <Navbar className="contact-navbar" isBlack= "true" />
        <Header />
        <Main />
        <Body />
        <Sub />
        <Subfooter />
        <Footer />
    </BlogContainer>
  )
}

export default Blog