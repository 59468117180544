import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import { Typography } from "@mui/material";
import "swiper/css";
import "swiper/scss/navigation";
import {
  Testimonials,
  TestimonialBoxA,
  TestimonialBoxB,
  TestimonialChild,
  TestimonialContainer,
  SubText,
  BoxAChild,
} from "./testimonial.style";
import testimonialShape from "../../../assets/images/testimonial-shape.png";

import medeowloft from "../../../assets/images/meadowloft.png";

import servolution from "../../../assets/images/servolution.png";
import researchRuth from "../../../assets/images/researchReviewLogo.png";

import havadoLogo from "../../../assets/images/havadocLogo.jpg";
import fxKara from "../../../assets/images/fxKara.png";
import which from "../../../assets/images/whinc.jpg";
const TestimonialItem = ({ image, text, company, name }) => (
  <Testimonials className="testimonials">
    <div className="imgWrapper">
      <img src={image} alt="testimony" className="testimony" />
    </div>
    <Typography variant="body1">{text}</Typography>
    <SubText>
      <Typography className="company" variant="h6">
        {company}
      </Typography>
      <Typography className="name" variant="h6">
        {name}
      </Typography>
    </SubText>
  </Testimonials>
);

const Testimonial = () => {
  return (
    <TestimonialContainer>
      <TestimonialChild>
        <TestimonialBoxA>
          <BoxAChild>
            <div className="line"></div>
            <Typography variant="h6 header">TESTIMONIAL</Typography>
          </BoxAChild>
          <Typography variant="h5 subheader">
            Our clients span across various markets in Nigeria. See how some of
            them feel about us.
          </Typography>
        </TestimonialBoxA>
        <TestimonialBoxB>
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            speed={1000}
            direction="vertical"
            autoplaySpeed={3000}
            navigation
          >
            <img src={testimonialShape} alt="WIHCN" className="test" />
            <SwiperSlide>
              <TestimonialItem
                image={which}
                text="You did well. You never complained and kept it going until your client was happy. That is to be celebrated! A thank you from me for never saying no until the job on WIHCN was done!"
                company=" Dr. Modupe Elebute-Odunsi"
                name="Chairman/ Founder of WIHCN"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={researchRuth}
                text="Many thanks for sharing the link. We are very impressed with what we see so far."
                company="Yemisi Osakwe"
                name="Research Operations Lead, Research & Development
Marcelle Ruth Cancer Centre & Specialist Hospital"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={medeowloft}
                text="Thank you Peter. It's been a pleasure working with you."
                company="Ariyo Oluboye"
                name="Meadowloft"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={servolution}
                text="You know the project went on high speed. Faster than earlier expectations. Good job on the project."
                company="Dominion Elshaddai"
                name="Founder, Servolution "
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={havadoLogo}
                text="Petrong Software Solutions are legitimate, very industrous and creative company. They've served us very well as vendors and we have 
a good relationship with their founder Peter."
                company="Isaac Akinpelu"
                name="Founder, Havadoc"
              />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialItem
                image={fxKara}
                text="What I like is that, Even without great documentation you interpreted it in a way whereby it meets my exact requirements. I thank God that I met you. Many thanks for all the work you did. "
                company="Oluwole Solanke"
                name="Founder, FxKarasell"
              />
            </SwiperSlide>
          </Swiper>
        </TestimonialBoxB>
      </TestimonialChild>
    </TestimonialContainer>
  );
};

export default Testimonial;
