import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import styled from "styled-components";

const LoadingBackdrop = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);  // Backdrop color (semi-transparent black)
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;  // Ensure it's on top of everything
`;

const LoaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoaderText = styled(Typography)`
  color: #00d285;  // Green color
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
`;

const LoadingPage = () => {
  return (
    <LoadingBackdrop>
      <LoaderContainer>
        <CircularProgress
          style={{ color: '#00d285' }}
          size={80}  
        />
        <LoaderText>Loading, please wait...</LoaderText>
      </LoaderContainer>
    </LoadingBackdrop>
  );
};

export default LoadingPage;
