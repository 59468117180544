import React from 'react'
import { Helmet } from "react-helmet";
import Footer from '../../components/shared-components/footer/Footer'
import Navbar from '../../components/shared-components/navbar/Navbar'
import Body from '../../components/single-blog-components/body/Body';
import { SingleContainer } from './singleBlog.style'

const SingleBlog = () => {
  return (
    <SingleContainer>
           <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Petrong Software Company - Single Blog" />
        <title>Petrong - Blog</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
      </Helmet>
        <Navbar isBlack= "true"/>
        <Body />
        <Footer />
    </SingleContainer>
  )
}

export default SingleBlog