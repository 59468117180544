import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { QuoteContainer } from './quote.style'
import Navbar from '../../components/shared-components/navbar/Navbar'
import Footer from '../../components/shared-components/footer/Footer'
import Main from '../../components/quote-components/main/Main'
import contact from "../../assets/images/contact.png";
import Sub from '../../components/quote-components/sub/Sub'
import group from "../../assets/images/group.png";
import Subfooter from '../../components/shared-components/subfooter/Subfooter'


const Quote = () => {

  useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

  return (
    <QuoteContainer>
       <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Petrong Software Company - Quote" />
        <meta name="keywords" content="Petrong, Software, Company, Quote" />
        <meta name="author" content="Petrong Software" />
        <meta name="robots" content="index,follow" />
        <title>Petrong - Quote</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
      </Helmet>
        <Navbar className="contact-navbar" isBlack= "true"/>
        <img src={contact} alt="contact" className="contact-img"/>
        <img src={group} alt="group" className="group"/>
        <Main />
        <Sub />
        <Subfooter />
        <Footer />
    </QuoteContainer>
  )
}

export default Quote